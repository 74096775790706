import React, { FC, useCallback, useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { renderToString } from 'react-dom/server'
import CustomTooltipReport from '@/pages/Reports/BloggerReport/Cards/CustomGraph/CustomTooltipReport'
import { shortNum } from '@/_helpers/_graphs/_tech'

interface CustomGraphProps {
    graphData: number[]
    type: 'subscribers' | 'views' | 'er' | string
    graphCategories: string[]
    locale: string
    height?: number
    isDemo?: boolean
    hideAxis?: boolean
}

export const CustomGraph: FC<CustomGraphProps> = ({
    graphData,
    type,
    graphCategories,
    locale,
    height = 280,
    isDemo = false,
    hideAxis = false,
}) => {
    const graphRef = useRef<HTMLDivElement>(null)

    const formatDate = useCallback(
        (date: string): string => {
            const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' }
            return new Date(date).toLocaleDateString(locale, options)
        },
        [locale]
    )

    const getOptions = useCallback((): ApexOptions => {
        const isEmpty = graphData.length === 0
        const categories = isEmpty
            ? Array(40).fill('Plug') // Заглушка
            : graphCategories.map(formatDate)

        const data = isEmpty ? Array(40).fill(0) : graphData

        return {
            chart: {
                animations: { enabled: false },
                height,
                type: 'area',
                toolbar: { show: false },
                zoom: { enabled: false },
                defaultLocale: locale,
                locales: [
                    {
                        name: 'en',
                        options: {
                            shortMonths: [
                                'Jan',
                                'Feb',
                                'Mar',
                                'Apr',
                                'May',
                                'Jun',
                                'Jul',
                                'Aug',
                                'Sep',
                                'Oct',
                                'Nov',
                                'Dec',
                            ],
                        },
                    },
                    {
                        name: 'ru',
                        options: {
                            shortMonths: [
                                'Янв',
                                'Фев',
                                'Мар',
                                'Апр',
                                'Май',
                                'Июн',
                                'Июл',
                                'Авг',
                                'Сен',
                                'Окт',
                                'Ноя',
                                'Дек',
                            ],
                        },
                    },
                ],
            },
            fill: { colors: ['#6071FF'] },
            markers: { size: 0 },
            stroke: { width: 2, colors: ['#6071FF'] },
            series: [{ name: 'data', data }],
            dataLabels: { enabled: false },
            tooltip: {
                enabled: !isEmpty,
                custom: (options) =>
                    renderToString(
                        <CustomTooltipReport
                            type={type}
                            series={options.series}
                            seriesIndex={options.seriesIndex}
                            dataPointIndex={options.dataPointIndex}
                            w={options.w}
                            date={graphCategories[options.dataPointIndex]}
                        />
                    ),
            },
            yaxis: {
                labels: {
                    show: !hideAxis,
                    formatter: (val: any) => (type === 'er' ? `${shortNum(val * 100, 1, 1)}%` : shortNum(val, 2)), // Форматируем значения оси Y
                },
                axisTicks: { show: !hideAxis },
            },
            xaxis: {
                labels: {
                    show: !hideAxis,
                    style: { fontSize: '10px', colors: '#8F8F8F' },
                    formatter: (val: string) => val,
                },
                categories,
                tooltip: { enabled: false },
            },
        }
    }, [graphData, graphCategories, locale, height, hideAxis, type, formatDate])

    useEffect(() => {
        if (graphRef.current) {
            const chart = new ApexCharts(graphRef.current, getOptions())
            chart.render()

            return () => {
                chart.destroy()
            }
        }
    }, [getOptions, graphData, graphCategories, locale, height, hideAxis])

    return <div ref={graphRef} style={isDemo ? { filter: 'blur(5px)' } : {}} />
}
