import Service from '@/_helpers/Service'

const ParseService = new Service({
    method: 'POST',
    url: 'api/new_parsing/instagram/blogger/',
})

export const parsingRequest = (token: string, username: string) => {
    return ParseService.call(
        {
            data: { blogger_username: username },
        },
        token
    )
}
