import { shortNum } from '@/_helpers/_graphs/_tech'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import moment from 'moment'
import { FC } from 'react'
import cn from './tooltip.module.scss'
import React from 'react'
import { useMemo } from 'react'
export interface ITooltip {
    series: Array<Array<number>>
    seriesIndex: number
    dataPointIndex: number
    w: any
    type: string
    date: string
}
const translate = {
    followers: 'subs',
    follows: 'subscriptions',
    er: 'er',
}

const Index: FC<ITooltip> = (props) => {
    const t = useSpaceTranslation('report_layout.graph')
    const val = useMemo(() => {
        const metric = props.series[props.seriesIndex][props.dataPointIndex]

        return props.type === 'er' ? shortNum(metric * 100, 1, 3) + '%' : shortNum(metric)
    }, [props])

    return (
        <div className={cn.tooltip}>
            <div className={cn.date}>{moment(props.date).format('DD.MM.YYYY')}</div>

            <div className={cn.value}>{val}</div>

            <div className={cn.type}>{t(`${translate[props.type as keyof typeof translate]}`)}</div>
        </div>
    )
}
export default Index
