import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'

import cn from './DownloadExcel.module.scss'
import useOpenClose from '@/_helpers/useOpenClose'
import Popup from '@/_yc/Popup'

import Text from '@/components/Text'
import Flex from '@/_yc/Flex'
import { useSearch, useSorting } from '@/pages/SearchPage/SearchAdapter'
import { getFilterQueries } from '@/pages/SearchPage/SearchAdapter/adsExplorer/instagram/filters/utils/getFilterQueries'
import { getPostsQuery } from '@/pages/SearchPage/SearchAdapter/adsExplorer/instagram/queries'
import { useMutation } from '@apollo/client'
import { GET_DOWNLOAD_LINK } from './queries'
import { getGqlString } from '@/_helpers/graphql/getString'
import Icons from '@/components/Icons'
import { IDownloadLinkData } from '../types'
import { useSpaceTranslation } from '@/_helpers'
import { useCount } from '@/pages/SearchPage/SearchAdapter/hooks/useCount'
import { useLocation } from 'react-router-dom'
import { findAdapterType } from '@/pages/SearchPage/utils/findAdapterType'
import { getYouTubePostsQuery } from '@/pages/SearchPage/SearchAdapter/adsExplorer/youtube/queries'
import { DownloadParams } from './DownloadParams'
import { useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'

interface IDownloadExcel {}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const DownloadExcel: FC<IDownloadExcel> = () => {
    const t = useSpaceTranslation('ads_explorer.download_excel')

    const [isOpen, open, close] = useOpenClose()
    const [getDownloadLink, { data, loading, error }] = useMutation<IDownloadLinkData>(GET_DOWNLOAD_LINK)
    const [numTokens, setNumTokens] = useState(0)
    const count = useSelector((store: RootStore) => store.HeaderMenu_.ReportCounter_.reportsCount)

    const { filters } = useSearch((state) => state)
    const { sorting } = useSorting()

    const loc = useLocation()
    const curPlatform = findAdapterType(loc.pathname)

    const numPosts = useCount()

    const loadPosts = useCallback(
        (dryRun) => {
            const queryFunc = curPlatform === 'instagram' ? getPostsQuery : getYouTubePostsQuery

            const queryParams = {
                orderBy: sorting as string,
                filters: getFilterQueries(filters),
            }

            const query = queryFunc(queryParams)
            getDownloadLink({
                variables: {
                    dryRun: dryRun,
                    graphqlQuery: getGqlString(query),
                    postsCount: numPosts.count,
                },
            })
        },
        [filters, sorting, getDownloadLink, numPosts, curPlatform]
    )

    const notEnoughFundsError = useMemo(() => data?.exportExcel?.result.__typename === 'NotEnoughFundsError', [data])

    const notEnoughFunds = useMemo(
        () => (count || 0) - numTokens < 0 || notEnoughFundsError,
        [numTokens, count, notEnoughFundsError]
    )

    useEffect(() => {
        if (loading || error || !data) return

        if (data.exportExcel?.result?.details) return

        setNumTokens(data.exportExcel.result.price)

        const link = data.exportExcel.result.downloadLink

        if (link) window.location.href = link
    }, [loading, data, error, close])

    const openPopup = useCallback(() => {
        loadPosts(true)
        open()
    }, [loadPosts, open])

    return (
        <>
            <div onClick={openPopup}>
                <LoadingButton className={cn.loadingButton}>{t('btn')}</LoadingButton>
            </div>

            {isOpen && (
                <div className={cn.root}>
                    <Popup style={style} isOpen={isOpen} onClose={close}>
                        <div className={cn.popup}>
                            <div onClick={close} className={cn.close}>
                                <Icons name="close--small" />
                            </div>

                            <Text fSize={18}>{t('title')}</Text>

                            <Text fSize={14} color={'gray-3'} margin={'20px 0 0 0'}>
                                {t('subtitle')}
                            </Text>

                            <DownloadParams numPosts={numPosts.count} numTokens={numTokens} />

                            <Text fSize={10} color={'gray-4'}>
                                {t('max')}
                            </Text>

                            <Flex className={cn.footer}>
                                <Flex column>
                                    <Flex>
                                        <Text fSize={12} color={'gray-4'}>
                                            {t('flags')}
                                        </Text>
                                        <Text
                                            fSize={12}
                                            color={notEnoughFunds ? 'red-2' : 'gray-2'}
                                            margin={'0 0 0 6px'}
                                        >
                                            {count}
                                        </Text>

                                        <Text
                                            fSize={12}
                                            color={notEnoughFunds ? 'red-2' : 'gray-2'}
                                            margin={'0 0 0 6px'}
                                        >
                                            {t('tokens').toLowerCase()}
                                        </Text>
                                        <Icons name={'flag'} className={notEnoughFunds ? cn['icon--red'] : cn.icon} />
                                    </Flex>
                                    {(notEnoughFundsError || notEnoughFunds) && (
                                        <Text fSize={10} color={'red-2'}>
                                            {t('not_enough')}
                                        </Text>
                                    )}
                                </Flex>
                                <div onClick={() => (notEnoughFunds ? null : loadPosts(false))}>
                                    <LoadingButton
                                        noDownloadIcon
                                        loading={loading}
                                        color={'white'}
                                        disabled={notEnoughFunds}
                                    >
                                        {t('download')}
                                    </LoadingButton>
                                </div>
                            </Flex>
                        </div>
                    </Popup>
                </div>
            )}
        </>
    )
}
