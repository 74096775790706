import { Adapter } from '../../Adapter'
import SearchItem from './SearchItem/SearchItem'
import { getFilters } from './filters/filters'
import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getPostsQuery } from './queries'
import { getFilterQueries } from './filters/utils/getFilterQueries'
import { IGetPosts } from './types'
import { getItems } from './utils/getItems'
import { getCount } from './utils/getCount'
import ItemsHead from '../Head/ItemsHead'
import service from '@/pages/Auth/LoginPage/Model/_service'

const fetcher = new Fetcher()

export const AdsExplorerInstagramAdapter = new Adapter({
    Icon: () => null,
    title: 'AdsExplorerInstagram',
    templateColumns: '',
    fetcher: async (_, config) => {
        const client = fetcher.getClient()
        const queryParams = {
            orderBy: config.sorting,
            cursor: config.cursor,
            filters: getFilterQueries(config.filters),
            limit: 20,
        }
        const posts = await client.query<IGetPosts>({
            query: getPostsQuery(queryParams),
        })
        const items = getItems(posts)

        // @ts-ignore
        const platformsRes = await service.getSubscriptionPlatforms()
        const platforms: string[] = platformsRes.tariff?.platforms
        const isTariffIncludesAds = platforms?.includes('ads') || false
        const isHidden = !isTariffIncludesAds
        const hasNext = isTariffIncludesAds || config.page !== 1

        const sliceIndex = isHidden ? 3 : items.length

        return {
            hasNext: hasNext,
            total: getCount(posts),
            items: items.slice(0, sliceIndex),
            isHidden: isHidden,
            cursor: posts.data?.igPosts?.pageInfo?.endCursor,
        }
    },
    countCheck: async (_, config) => {
        const client = fetcher.getClient()

        const queryParams = {
            orderBy: config.sorting as string,
            limit: 20,
            filters: getFilterQueries(config.filters),
        }

        const posts = await client.query<IGetPosts>({
            query: getPostsQuery(queryParams),
        })

        return {
            count: getCount(posts) || 0,
        }
    },
    background: 'red',
    Item: SearchItem,
    Head: ItemsHead,
    sortings: [
        { label: 'ads_explorer.sortings.likes', value: 'likes: DESC' },
        { label: 'ads_explorer.sortings.post_date', value: 'postDate: DESC' },
    ],
    keyWords: [{ label: 'error', value: 'error' }],
    filters: getFilters(fetcher),
})
