import React, { useEffect, useState } from 'react'
import Tabs from './components/Tabs'
import SearchBar from './components/SearchBar'
import ListTable from './components/ListTable'
import PaginationButton from './components/PaginationButton'
import useClass from '@/_helpers/Hooks/useClass'
import cn from './report-list-page.module.scss'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Flex } from '@/_yc' // Добавляем Spinner для прелоадера
import Text from '@/components/Text'
import { ReactComponent as Icon } from '@/pages/Lists/icons/download.svg'
import ArrowRight from '@/components/Icons/UI/ArrowRight'
import { GetAllReportsReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { ReportResponse } from '@/pages/Lists/model/types'
import { useHistory } from 'react-router-dom'

interface ListItem {
    id: number
    status: string
    unloading_place: string
    url: string
    count_of_bloggers_in_report: number
    created_at: string
    updated_at: string
    name_of_list: string
}

const tabs = [
    { title: 'Lists' },
    { title: 'Competitor Analysis', disabled: true },
    { title: 'PDF Reports', disabled: true },
    { title: 'Overlaps', disabled: true },
]

const ReportListPage = () => {
    const [activeTab, setActiveTab] = useState(0)
    const [items, setItems] = useState<ListItem[]>([]) // Отображаемые элементы
    const [originalItems, setOriginalItems] = useState<ListItem[]>([]) // Исходные данные
    const [selectedItems, setSelectedItems] = useState<number[]>([])
    const [nextPageUrl, setNextPageUrl] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false) // Для прелоадера
    const root = useClass(cn.root)
    const { t } = useTranslation()
    const token = useToken()
    const history = useHistory()

    const handleTabClick = (index: number) => setActiveTab(index)

    const handleSearch = (query: string) => {
        const filteredItems = originalItems.filter((item) =>
            item.name_of_list.toLowerCase().includes(query.toLowerCase())
        )
        setItems(filteredItems) // Отображаем отфильтрованные данные
    }

    const handleSelectItem = (id: number | 'all', checked: boolean) => {
        setSelectedItems((prev) => {
            if (id === 'all') {
                return checked ? items.map((item) => item.id) : []
            }
            return checked ? [...prev, id] : prev.filter((itemId) => itemId !== id)
        })
    }

    const loadReports = (url?: string) => {
        setIsLoading(true) // Включаем прелоадер
        GetAllReportsReq(token as string, url)
            .then((res: ReportResponse) => {
                setItems((prevItems) => [...prevItems, ...res.results]) // Отображаем данные
                setOriginalItems((prevItems) => [...prevItems, ...res.results]) // Обновляем оригинальные данные
                setNextPageUrl(res.next) // Сохраняем следующую страницу
            })
            .catch((err) => {
                console.error('Failed to fetch reports:', err)
            })
            .finally(() => setIsLoading(false)) // Отключаем прелоадер
    }

    const handleShowMore = () => {
        if (nextPageUrl) {
            loadReports(nextPageUrl) // Загружаем следующую страницу
        }
    }

    useEffect(() => {
        loadReports()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return (
        <PageLayout color={'common-background'} className={root} title={t('titles.t2')}>
            <div className={cn['page']}>
                <Flex align="end" margin="0 0 24px 0">
                    <Icon className={cn['icon']} />
                    <Flex margin="0 0 0 20px" column>
                        <Text fSize="30px" semibold color="gray-new-1">
                            Downloads
                        </Text>
                        <Flex align="center">
                            <ArrowRight className={cn['icon-back']} />
                            <Text
                                onClick={() => history.goBack()}
                                style={{ cursor: 'pointer' }}
                                fSize="12px"
                                semibold
                                color="gray-new-3"
                            >
                                Back
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
                <Flex margin="20px 0 20px 0">
                    <SearchBar onSearch={handleSearch} />
                </Flex>
                <ListTable
                    selectedItems={selectedItems}
                    items={items}
                    onSelectItem={handleSelectItem}
                    isLoading={isLoading}
                />
                {nextPageUrl && !isLoading && <PaginationButton onClick={handleShowMore} />}
            </div>
        </PageLayout>
    )
}

export default ReportListPage
