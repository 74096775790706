import Service from '@/_helpers/Service'

import {
    ISetOfCustomListInfoReq,
    ICustomListReq,
    ICustomListInfo,
    FavoriteBloggers,
    ListsEasy,
    ReportResponse,
    IGetRecentSearchesData,
} from './types'
//apply pagination here
const GetLists = new Service<ISetOfCustomListInfoReq>('')
export const GetListsReq = (token: string, page: number, tags?: string) =>
    GetLists.call(
        {
            url: `/api/sets/list/?page=${page}${tags ? `&tags=${tags}` : ''}`,
        },
        token
    )

const getCustomList = new Service<ICustomListReq[]>('')
export const getCustomListReq = (token: string, customListId: number, page: number, sort?: string) =>
    getCustomList.call(
        {
            url: `/api/sets/blogger/?set=${customListId}&page=${page}${sort ? '&' + sort : ''}`,
        },
        token
    )

const getFavoritesList = new Service<ISetOfCustomListInfoReq>('')
export const getFavoritesListReq = (token: string) =>
    getFavoritesList.call(
        {
            url: `/api/sets/list?is_likes=True`,
        },
        token
    )

const getCustomListInfo = new Service<ICustomListInfo>('')
export const getCustomListReqInfo = (token: string, customListId: number) =>
    getCustomListInfo.call(
        {
            url: `/api/sets/list/${customListId}`,
        },
        token
    )

const getListLockedCount = new Service<{ count: number }>('')
export const getListLockedCountReq = (token: string, customListId: number) =>
    getListLockedCount.call(
        {
            url: `/api/sets/blogger/locked/?set=${customListId}`,
        },
        token
    )

const DeleteCustomList = new Service<ICustomListInfo>({
    method: 'DELETE',
    url: '',
})
export const DeleteCustomListReq = (token: string, listInfo: ICustomListInfo) =>
    DeleteCustomList.call(
        {
            url: `/api/sets/list/${listInfo.id}/`,
            data: listInfo,
        },
        token
    )

const AddCustomList = new Service<ICustomListInfo>({
    method: 'POST',
    url: '/api/sets/list/',
})
export const AddCustomListReq = (token: string, listName: string, tags?: { name: string }[], comment?: string) =>
    AddCustomList.call(
        {
            data: {
                name: listName,
                tags: tags || [],
                comment: comment || null,
            },
        },
        token
    )
const RenameCustomList = new Service<ICustomListInfo>({
    method: 'PUT',
    url: '',
})
export const RenameCustomListReq = (token: string, listName: string, listId: number) =>
    RenameCustomList.call(
        {
            url: `/api/sets/list/${listId}/`,
            data: {
                name: listName,
                id: listId,
            },
        },
        token
    )
export const EditCustomListReq = (token: string, listName: string, listId: number, comment: string) =>
    RenameCustomList.call(
        {
            url: `/api/sets/list/${listId}/`,
            data: {
                comment: comment,
                tags: [],
                name: listName,
                id: listId,
            },
        },
        token
    )

interface IAddToList {
    total_count: string
}
const AddToList = new Service<IAddToList>({
    method: 'POST',
    url: '',
})
export const AddToListReq = (
    token: string,
    listId: number,
    external_id?: string[],
    channelId?: string[],
    instUsernames?: string[],
    twitchChannelsIds?: string[],
    twitchUsernames?: string[],
    youtubeUsernames?: string[],
    vkId?: string[],
    tgId?: string[],
    tikTokIdsList?: string[],
    send_ig_bloggers_on_full_parsing_if_needed?: boolean
) =>
    AddToList.call(
        {
            url: '/api/sets/blogger/?set=' + listId,
            data: {
                set_id: listId,
                external_ids: external_id || [],
                channel_ids: channelId || [],
                instagram_usernames: instUsernames || [],
                twitch_channels_ids: twitchChannelsIds || [],
                twitch_usernames: twitchUsernames || [],
                youtube_usernames: youtubeUsernames || [],
                vk_ids: vkId || [],
                telegram_ids: tgId || [],
                tiktok_ids: tikTokIdsList || [],
                send_ig_bloggers_on_full_parsing_if_needed:
                    send_ig_bloggers_on_full_parsing_if_needed !== undefined
                        ? send_ig_bloggers_on_full_parsing_if_needed
                        : true,
            },

            // external_id
            //     ? {
            //           set_id: listId,
            //           external_ids: external_id,
            //       }
            //     : {
            //           set_id: listId,
            //           external_ids: channelId,
            //           channel_ids: channelId,
            //       },
        },
        token
    )
export const AddToLikesReq = (
    token: string,
    external_id?: string[],
    channelId?: string[],
    instUsernames?: string[],
    twitchChannelsIds?: string[],
    twitchUsernames?: string[],
    vkIds?: string[],
    tgIds?: string[]
) =>
    AddToList.call(
        {
            url: '/api/sets/blogger/like/',
            data: {
                set_id: undefined,
                external_ids: external_id || [],
                channel_ids: channelId || [],
                instagram_usernames: instUsernames || [],
                twitch_channels_ids: twitchChannelsIds || [],
                twitch_usernames: twitchUsernames || [],
                vk_ids: vkIds || [],
                telegram_ids: tgIds || [],
            },
        },
        token
    )

const DeleteBloggerFormList = new Service<ICustomListInfo>({
    method: 'DELETE',
    url: '',
})
export const DeleteBloggerFormListReq = (token: string, bloggerId: number) =>
    DeleteBloggerFormList.call(
        {
            url: `/api/sets/blogger/${bloggerId}/`,
        },
        token
    )

/*
        deleteBlogger: id=>({
            url: `/api/sets/blogger/${id}/`,
            method: "DELETE",
            headers: authHeader()
        }),
*/

const AddTag = new Service<ICustomListInfo>({
    method: 'PUT',
    url: '',
})
export const AddTagReq = (token: string, listId: number, listName: string, tags: { name: string }[]) =>
    AddTag.call(
        {
            url: `/api/sets/list/${listId}/`,
            data: {
                tags: tags,
                name: listName,
            },
        },
        token
    )

const DeleteTag = new Service<ICustomListInfo>({
    method: 'DELETE',
    url: '',
})
export const DeletTagReq = (token: string, name: string) =>
    DeleteTag.call(
        {
            url: `/api/sets/list/delete_tag/`,
            data: {
                tag_name: name,
            },
        },
        token
    )

const GetTags = new Service<any>({
    method: 'GET',
    url: '',
})
export const GetTagsReq = (token: string) =>
    GetTags.call(
        {
            url: `/api/sets/list/get_tags/`,
        },
        token
    )

const Dislike = new Service<any>({
    method: 'DELETE',
    url: '',
})
export const DislikeReq = (token: string, ids: number) =>
    Dislike.call(
        {
            url: `/api/sets/blogger/dislike/`,
            data: {
                ids: [ids],
            },
        },
        token
    )

const AddCommentToBlogger = new Service<any>({
    method: 'PUT',
    url: '',
})
export const AddCommentToBloggerReq = (token: string, comment: string, id: string, comment_id: number) =>
    AddCommentToBlogger.call(
        {
            url: `/api/sets/comment/${comment_id}/`,
            data: {
                text: comment,
                external_id: id,
            },
        },
        token
    )
const CreateCommentToBlogger = new Service<any>({
    method: 'POST',
    url: '/api/sets/comment/',
})
export const CreateCommentToBloggerReq = (token: string, comment: string, id: string) =>
    CreateCommentToBlogger.call(
        {
            data: {
                text: comment,
                external_id: id,
            },
        },
        token
    )

const getFavoritesBloggers = new Service<FavoriteBloggers[]>({
    method: 'GET',
    url: '',
})
export const getFavoritesBloggersReq = (token: string) =>
    getFavoritesBloggers.call(
        {
            url: `/api/sets/blogger/likes/`,
        },
        token
    )

const GetBloggersComments = new Service<any>({
    method: 'GET',
    url: '',
})
export const GetBloggersCommentsReq = (token: string) =>
    GetBloggersComments.call(
        {
            url: `/api/sets/comment/all/`,
        },
        token
    )

const getListsWOpagination = new Service<ListsEasy[]>('/api/sets/list/get_all_sets/')
export const getListsWOpaginationReq = (token: string) => getListsWOpagination.call({}, token)

const CreateReport = new Service<null>({
    method: 'GET',
    url: '/api/sets/async_export/',
})

export const CreateReportReq = (token: string, setId: number, openReports: boolean = true) =>
    CreateReport.call(
        {
            url: `/api/sets/async_export/?set=${setId}&open_reports=${openReports}/`,
        },
        token
    )

const GetReports = new Service<ReportResponse>({
    method: 'GET',
    url: '',
})

export const GetAllReportsReq = (token: string, url?: string) => {
    const requestUrl = url || '/api/reports/all'
    return GetReports.call({ url: requestUrl }, token)
}
const RecentSearches = new Service<IGetRecentSearchesData>('')
export const RecentSearchesReq = (token: string, page: number) =>
    RecentSearches.call(
        {
            url: '/api/core/recent_searches_v2/',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                input: { page },
            },
        },
        token
    )
